<template>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div v-on:click="go_to_student()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Students</span>
						<div class="text-900 font-medium text-xl">{{dashcount.student}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div v-on:click="go_to_teacher()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Teachers</span>
						<div class="text-900 font-medium text-xl">{{dashcount.teacher}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-user text-orange-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">%52+ </span>
				<span class="text-500">since last week</span> -->
			</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div v-on:click="go_to_subject()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<!-- <div>
						<span class="block text-500 font-medium mb-3">Subjects</span>
						<div class="text-900 font-medium text-xl">{{dashcount.subject}}</div>
					</div> -->
					<div>
						<span class="block text-500 font-medium mb-3">Devices</span>
						<div class="text-900 font-medium text-xl">{{totalRecords?totalRecords:"0"}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-mobile text-cyan-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">520  </span>
				<span class="text-500">newly registered</span> -->
			</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div v-on:click="go_to_user()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">User Queries</span>
						<div class="text-900 font-medium text-xl">{{dashcount.user_Queries}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-comment text-purple-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">85 </span>
				<span class="text-500">responded</span> -->
			</div>
			</div>
		</div>

	<div class="col-12 xl:col-6">
		<div class="card">
			<div class="grid">
			<div class="col-8">
			<h5>Data Usages</h5>
			</div>
			<div class="col-4">
			<Calendar
              style="width: 100%"
              v-model="get_date"
              :showIcon="true"
              dateFormat="d-MM-yy"
              hourFormat="12"
              :numberOfMonths="1"
			  v-on:select="datausagereport()"
            />
			</div>
			</div>
			<!-- <DataTable :value="products" :rows="5" :paginator="true" responsiveLayout="scroll">
				<Column style="width:15%">
					<template #header>
						Image
					</template>
					<template #body="slotProps">
						<img :src="'images/product/' + slotProps.data.image" :alt="slotProps.data.image" width="50" class="shadow-2" />
					</template>
				</Column>
				<Column field="name" header="Name" :sortable="true" style="width:35%"></Column>
				<Column field="price" header="Price" :sortable="true" style="width:35%">
					<template #body="slotProps">
						{{formatCurrency(slotProps.data.price)}}
					</template>
				</Column>
				<Column style="width:15%">
					<template #header>
						View
					</template>
					<template #body>
						<Button icon="pi pi-search" type="button" class="p-button-text"></Button>
					</template>
				</Column>
			</DataTable> -->
			<!-- {{barData}} -->
			
				<Chart type="bar" :data="barData" :options="barOptions" />
		</div>
		<div class="card">
			<div class="flex justify-content-between align-items-center mb-5">
				<h5>Todays Lectures</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
					<Menu ref="menu2" :popup="true" :model="items"></Menu>
				</div>
			</div>
			<ul class="list-none p-0 m-0">
				<!-- {{todayslectures}} -->
				<li v-for="(lec,key) in todayslectures" :key="key" class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<!-- <div>  -->
						<!-- <img :src="lec.image" height="80" width="60"> -->
					 <!-- </div>  -->
					<div class="">
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{lec.teacheR_NAME}}</span>
						<div class="mt-1 text-600">SUB-{{lec.subject}}</div>
						<div class="mt-1 text-600">TOPIC-{{lec.discuessioN_ON_TOPIC}}</div>
						<div class="mt-1 text-600">SCHEDULE ON{{lec.from_time}}-{{lec.to_time}}</div>
		
					</div>
					<div class="mt-2 md:mt-0 flex align-items-center">
						<img  :src="lec.image" height="80" width="60">
						
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="col-12 xl:col-6">
		<div class="card">
			

			<div class="grid">
			<div class="col-8">
			<h5>Network Connectivity</h5>
			</div>
			<div class="col-4">
			<Calendar
              style="width: 100%"
              v-model="get_date_2"
              :showIcon="true"
              dateFormat="d-MM-yy"
              hourFormat="12"
              :numberOfMonths="1"
			  v-on:select="datausagereport()"
            />
			</div>
			</div>


			<Chart type="bar" :data="lineData" :options="lineOptions" />
		</div>
		<div class="card">
			<div class="flex align-items-center justify-content-between mb-4">
				<h5>No Of Lectures by Teacher</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu1.toggle($event)"></Button>
					<Menu ref="menu1" :popup="true" :model="items"></Menu>
				</div>
			</div>
			
			
			<Chart type="bar" :data="barData1" :options="barOptions1" />
		</div>
		<div class="grid">
		<div class="col-12 lg:col-6 xl:col-6">
			<div v-on:click="go_to_net_connect()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Network Connectivity</span>
						<div class="text-900 font-medium text-xl">{{dashcount.network_connectivity}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-mobile text-blue-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-6">
			<div v-on:click="go_to_data_usage()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Data Usages</span>
						<div class="text-900 font-medium text-xl">{{dashcount.data_usages}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-mobile text-orange-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">%52+ </span>
				<span class="text-500">since last week</span> -->
			</div>
			
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-6">
			<div v-on:click="go_to_news()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">News</span>
						<div class="text-900 font-medium text-xl">{{dashcount.news}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-inbox text-cyan-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">520  </span>
				<span class="text-500">newly registered</span> -->
			</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-6">
			<div v-on:click="go_to_Feedback()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Feedback</span>
						<div class="text-900 font-medium text-xl">{{dashcount.feedback}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-comment text-purple-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">85 </span>
				<span class="text-500">responded</span> -->
			</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-6">
			<div v-on:click="go_to_Scemes()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Schemes</span>
						<div class="text-900 font-medium text-xl">{{dashcount.schemes}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-comment text-purple-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">85 </span>
				<span class="text-500">responded</span> -->
			</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-6">
			<div v-on:click="go_to_Notice()">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Notice</span>
						<div class="text-900 font-medium text-xl">{{dashcount.notice}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-comment text-purple-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">85 </span> -->
				<!-- <span class="text-500">responded</span> -->
			</div>
			</div>
		</div>
		</div>	
		<!-- <div class="px-4 py-5 shadow-2 flex flex-column md:flex-row md:align-items-center justify-content-between mb-3" 
			style="border-radius: 1rem; background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)">
			<div>
				<div class="text-blue-100 font-medium text-xl mt-2 mb-3">TAKE THE NEXT STEP</div>
				<div class="text-white font-medium text-5xl">Try PrimeBlocks</div>
			</div>
			<div class="mt-4 mr-auto md:mt-0 md:mr-0">
				<a href="https://www.primefaces.org/primeblocks-vue" class="p-button font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised">
					Get Started
				</a>
			</div>
		</div> -->
	</div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';
import ProductService from '../service/ProductService';
import apis from "@/apis";

export default {
	data() {
		return {
			totalRecords:"",
			get_date:"",
			get_date_2:"",
			products: null,
			todayslectures:[],
			dashcount:{
				"teacher": 0,
				"student": 0,
				"subject": 0,
				"user_Queries": 0,
				"network_connectivity": 0,
				"data_usages": 0,
				"news": 0,
				"feedback": 0,
				"schemes": 0,
				"notice": 0
			},
			lineData: {
				labels: ['0','0 to 20', '20 to 40', '40 to 60', '60 to 80', '80 to 100'],
				datasets: [
					{
						label: 'Network',
						data: [],
						backgroundColor: '#00bb7e',
						
					},
					// {
					// 	label: 'Sales',
					// 	data: [],
					// 	fill: false,
					// 	backgroundColor: '#00bb7e',
					// 	borderColor: '#00bb7e',
					// 	tension: 0.4
					// }
				]
			},
			barData: {
				labels: ['0 to 1 GB', '1 to 4 GB', '4 to 6 GB', '6 to 10 GB', '10 GB Above'],
				datasets: [
					{
						label: 'Data Usages',
						backgroundColor: '#2f4860',
						data: []
					}
				]
			},
				barData1: {
				labels: [],
				datasets: [
					{
						label: 'No Of Lectures by Teacher',
						backgroundColor: '#2f4860',
						data: []
					}
				]
			},
			barOptions:null,
			barOptions1:null,
			items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
		}
	},
	productService: null,
	themeChangeListener: null,
	mounted() {
		this.productService.getProductsSmall().then(data => this.products = data);

		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
		this.dashboardcount();
		this.devicecount();
		this.get_date=new Date();
		this.datausagereport();
		this.get_date_2=new Date();
		this.networkconnectivityreport();
		this.todayslecturereport();
		this.teacherlecturereportadmin();
	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	created() {
		this.productService = new ProductService();

	},
	watch:{
		get_date()
		{
			this.datausagereport();
		},
		get_date_2()
		{
			this.networkconnectivityreport();
		}
	},
	methods: {
		go_to_student()
		{	//alert(987)
			this.$router.push('/studentmaster');
		},
		go_to_teacher()
		{
			this.$router.push('/teachermaster');
		},
		go_to_subject()
		{
			this.$router.push('/subjectmaster/12');
		},
		go_to_user()
		{
			this.$router.push('/userqueriesmaster');
		},
		go_to_net_connect()
		{
			this.$router.push('/userqueriesmaster');
		},
		go_to_data_usage()
		{
			this.$router.push('/userqueriesmaster');
		},
		go_to_news()
		{
			this.$router.push('/NewsMaster/6');
		},
		go_to_Feedback()
		{
			this.$router.push('/userqueriesmaster');
		},
		go_to_Scemes()
		{
			this.$router.push('/SchemeMaster/10');
		},
		go_to_Notice()
		{
			this.$router.push('/NoticeMaster');
		},
		devicecount()
		{
			var data = {
            "groupId": 5,
            "configurationId": -1,
            "pageNum":1,
            "pageSize": 1,
            "sortBy": null,
            "sortDir": "ASC"
        };
       
        var promise = apis.devicelist(data);
        promise.then((response) => {
         
       
          this.totalRecords = response.data.data.devices.totalItemsCount;
          
        });
		},
		dashboardcount(){
				  var promise = apis.dashboardcount();
      promise
        .then(response => {
			// alert(1);
			// console.log(this.barData.datasets[0].data);
			this.dashcount=response.data;
		});

		},
		networkconnectivityreport(){
			var D = new Date(this.get_date_2);
			
		if(this.get_date_2)
		{
		var date = D.toLocaleDateString("en-US", { year: "numeric" }) + "-" + D.toLocaleDateString("en-US", { month: "2-digit" }) + "-" + D.toLocaleDateString("en-US", { day: "2-digit" });
		}
		var data = {date:date};
				  var promise = apis.networkconnectivityreport(data);
      promise
        .then(response => {
			this.lineData.datasets[0].data=Object.values(response.data);
		});

		},
		datausagereport()
		{
			var D = new Date(this.get_date);
			
		if(this.get_date)
		{
		var date = D.toLocaleDateString("en-US", { year: "numeric" }) + "-" + D.toLocaleDateString("en-US", { month: "2-digit" }) + "-" + D.toLocaleDateString("en-US", { day: "2-digit" });
		}
		var data = {date:date};
		var promise = apis.dashboarddatausagereport(data);
      promise
        .then(response => {
			// alert(1);
			// console.log(this.barData.datasets[0].data);
			this.barData.datasets[0].data=Object.values(response.data);
		});

		},
		teacherlecturereportadmin(){
				  var promise = apis.teacherlecturereportadmin();
      promise
        .then(response => {
			// alert(1);
			// console.log(this.barData.datasets[0].data);
			this.barData1.labels=response.data.teacher;
			
			this.barData1.datasets[0].data=response.data.lectures;
		});

		},
		todayslecturereport(){
			var data={
			"limit":5,
    		"page_no":1
			}
			
				  var promise = apis.todayslecturereport(data);
      promise
        .then(response => {
			// alert(1);
			// console.log(this.barData.datasets[0].data);
			this.todayslectures=response.data;
		});

		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		},
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.barOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
			this.barOptions1 = {
				indexAxis: 'y',
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>